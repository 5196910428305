import { ReactElement } from "react";
import style from "./style.module.scss";

export default function Lawsuits(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <div className="md:w-11/12 mx-auto">
                    <h2>What Is Roundup?</h2>
                    <p>
                        Roundup is an herbicide that contains glyphosate and is
                        widely used for weed control in agriculture and
                        landscaping. Each year, in the U.S., approximately 250
                        million pounds of glyphosate are sprayed on crops,
                        commercial nurseries, lawns, driveways, sidewalks,
                        parks, and golf courses.
                    </p>
                </div>
            </div>
        </div>
    );
}
